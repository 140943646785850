import useIndicators from "../../../hooks/useIndicators";
import { Loader } from "../../moleculas";
import StatisticsCategories from "../../organims/Statistics/StatisticsCategory";
import StatisticsDescription from "../../organims/Statistics/StatisticsDescription";
import StatisticsSAHM from "../../organims/Statistics/StatisticsSAHM";

const Statistics = () => {
  const { loading, indicators } = useIndicators();

  return loading ? (
    <Loader />
  ) : (
    <div className="w-100 d-flex justify-content-md-start justify-content-lg-start flex-column">
      <div className="w-100 d-flex flex-column align-items-center">
        <StatisticsDescription />
      </div>
      <div className="w-100 my-5 d-flex flex-column align-items-center">
        <div className="w-75 d-flex flex-column align-items-center">
          <StatisticsCategories categories={indicators} />
        </div>
      </div>
      <div className="w-100 d-flex flex-column align-items-center">
        <StatisticsSAHM />
      </div>
    </div>
  );
};

export default Statistics;
