import { SIS } from "../../types/api/sis";
import {
  GET_LIBRARY_SUCCESS,
  GET_SIS_FAILURE,
  GET_SIS_STARTED,
  GET_SIS_SUCCESS,
} from "./../../constants/reduceActionTypes";

const defaultState = {
  library: undefined,
  sis: [] as SIS[],
  loading: false,
  error: null,
};

const SISReducer = (state = defaultState, action: any) => {
  switch (action.type) {
    case GET_SIS_STARTED: {
      return {
        ...state,
        loading: true,
      };
    }
    case GET_SIS_SUCCESS: {
      return {
        ...state,
        loading: false,
        sis: action.payload as SIS[],
      };
    }
    case GET_SIS_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    }
    case GET_LIBRARY_SUCCESS: {
      return {
        ...state,
        library: action.payload as SIS,
      };
    }
    default:
      return state;
  }
};

export default SISReducer;
