import { useTranslation } from "react-i18next";
import { SINIA_ICON } from "../../../assets";
import { FrequentQuestionsSection } from "../../organims";
import "./styles.scss";
import { HOME, SIS, SIS_ONE } from "../../../constants/routes";
import { CustomBreadcrumb, FeatureCard, Loader } from "../../moleculas";
import { useNavigate } from "react-router";
import useSIS from "../../../hooks/useSIS";
import { Button, Spinner } from "react-bootstrap";
import { CENIGA_URL, CMNUCC_URL } from "../../../constants/commond";

const SISView = () => {
  const { t } = useTranslation("sis");
  const { loading, sis, library } = useSIS();
  const navigate = useNavigate();

  return loading ? (
    <Loader />
  ) : (
    <div className="w-100 d-flex justify-content-md-start justify-content-lg-start flex-column">
      <div className="w-100 d-flex flex-row justify-content-center">
        {loading && <Spinner animation="border" variant="dark" />}
      </div>
      <div className="w-100">
        <div className="w-100 d-flex flex-column align-items-center justify-content-start background-color">
          <div className="w-100 d-flex justify-content-start flex-column align-items-center sis-banner-section">
            <div className="w-75 mb-lg-5 mb-0 d-flex flex-column align-items-center justify-content-start sis-banner-section-text">
              <div className="w-100 align-items-left">
                <CustomBreadcrumb
                  isWhite={false}
                  breadcrumbs={[
                    {
                      name: t("home"),
                      href: HOME,
                    },
                    { name: t("title"), href: SIS },
                  ]}
                />
                <div className="d-flex flex-column col-sm col-md-12 mb-2">
                  <div className="col-lg-7 col-12">
                    <h1 className="mb-3">{t("title-long")}</h1>
                    <hr className="custom-divider" />
                    <p>{t("description.p")}</p>
                    <p>{t("description.objectivesIntro")}</p>
                    <ul>
                      <li>{t("description.objectives.li1")}</li>
                      <li>{t("description.objectives.li2")}</li>
                      <li>{t("description.objectives.li3")}</li>
                      <li>{t("description.objectives.li4")}</li>
                    </ul>
                    <p>
                      {t("description.moreInfo")}{" "}
                      <a href={CENIGA_URL} target="_blank" rel="noreferrer">
                        {CENIGA_URL}
                      </a>
                    </p>
                    <p>
                      {t("description.hub")}{" "}
                      <a href={CMNUCC_URL} target="_blank" rel="noreferrer">
                        {CMNUCC_URL}
                      </a>
                    </p>
                    <Button
                      className="custom-primary-button w-25"
                      onClick={() => {
                        if (library.id) {
                          navigate(SIS_ONE.replace(":id", library.id));
                        }
                      }}
                    >
                      {t("description.library")}
                    </Button>
                  </div>
                </div>
              </div>
            </div>
            <div className="sis-banner-section-background"></div>
          </div>
        </div>
        <div className="w-100 d-flex flex-column align-items-center">
          <div className="w-75  feature-section-container">
            <div className="row w-100">
              {sis.map(
                (
                  card: { id: string; name: string; icon: string },
                  index: React.Key | null | undefined
                ) => (
                  <div key={index} className={`col-lg-4 mb-4 col-sm-12`}>
                    <FeatureCard
                      handleClick={() => {
                        if (card.id) {
                          navigate(SIS_ONE.replace(":id", card.id));
                        }
                      }}
                      txt={card.name}
                      icon={card.icon ? card.icon : SINIA_ICON}
                      boldText={true}
                    />
                  </div>
                )
              )}
            </div>
          </div>
        </div>
        <div className="w-100 d-flex flex-column align-items-center background-color">
          <div className="col-lg-6 col-10 mt-5">
            <FrequentQuestionsSection questionNumber={3} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SISView;
