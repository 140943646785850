import React from "react";
import { Card, Spinner } from "react-bootstrap";
import "./styles.scss";

export interface FileCardProp {
  className?: string;
  icon: string;
  column?: boolean;
  card: {
    link: string;
    txt: string;
  };
  action?: () => void;
  loading?: boolean;
}

const FileCard: React.FC<FileCardProp> = ({
  className,
  card,
  icon,
  column,
  action,
  loading,
}) => {
  return (
    <Card
      className={
        className
          ? className
          : `d-flex p-3 ${
              column ? "flex-column" : "flex-row"
            } align-items-center clickable`
      }
      onClick={action ? action : () => window.open(card.link, "_blank")}
    >
      <img className="file-card-img me-3" src={icon} />
      <h6 className="clamp-text">{card.txt}</h6>
      {loading && <Spinner animation="border" variant="dark" />}
    </Card>
  );
};

export default FileCard;
