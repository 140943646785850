import {
  SIS_CATEGORY,
  SIS_DOWNLOAD,
  SIS_INDICATOR,
} from "../../constants/endpoints";
import ServerError from "../../types/ServerError";
import { ISISService, SIS } from "../../types/api/sis";
import { throwable } from "../../utilities/ts-throwable";
import ApiClient from "./apiClient";
import axios from "axios";

class SISService implements ISISService {
  protected readonly apiClient: ApiClient;
  private url = process.env.REACT_APP_SIS || "";

  public constructor() {
    this.apiClient = new ApiClient(this.url);
  }

  getCategories = (): Promise<SIS[]> & throwable<ServerError> => {
    return this.apiClient.get(SIS_CATEGORY);
  };

  getIndicators = (
    categoryId: string
  ): Promise<any> & throwable<ServerError> => {
    return this.apiClient.get(SIS_INDICATOR.replace(":id", categoryId));
  };

  private _getFileURL = async (docId: string, signal?: any) => {
    try {
      const url = `${this.url}${SIS_DOWNLOAD.replace(":id", docId)}`;
      const response = await axios.get(url, {
        responseType: "arraybuffer",
        ...(signal && { signal }),
      });

      const { data, headers } = response;
      const contentType = headers["content-type"] || "application/octet-stream";
      const blob = new Blob([data], { type: contentType });

      const blobUrl = window.URL.createObjectURL(blob);
      return {
        url: blobUrl,
        contentType,
      };
    } catch (error) {
      throw new Error("Failed to download file");
    }
  };

  getFileURL = (docId: string, signal: any = null) => {
    return new Promise((accept, cancel) => {
      let abortHandler: any;
      if (signal instanceof AbortSignal) {
        abortHandler = () => {
          cancel({ aborted: signal.aborted });
        };
        signal.addEventListener("abort", abortHandler, { once: true });
      }

      this._getFileURL(docId, signal)
        .then((fileData) => {
          if (signal instanceof AbortSignal) {
            signal.removeEventListener("abort", abortHandler);
          }
          accept(fileData);
        })
        .catch((error) => {
          if (signal instanceof AbortSignal) {
            signal.removeEventListener("abort", abortHandler);
          }
          cancel(error.message);
        });
    });
  };
}

export default SISService;
