import { FC } from "react";
import { useDocFrame } from "./useDocFrame";
import { Button, Spinner } from "react-bootstrap";
import { CONTENT_TYPES, DOC_ICONS } from "../../../constants/commond";
import { useTranslation } from "react-i18next";
import "./styles.scss";
import { ErrorMessage } from "../../moleculas";

interface DocFrameProps {
  docUrl: any;
  selectedIndicator: any;
  selectedDocument: any;
  setDocUrl: any;
}

const DocFrame: FC<DocFrameProps> = ({
  docUrl,
  selectedIndicator,
  selectedDocument,
  setDocUrl,
}) => {
  const { t } = useTranslation("sis");
  const { loading, downloadDocument, error } = useDocFrame(
    selectedIndicator,
    selectedDocument,
    setDocUrl,
    docUrl
  );

  function isDisplayable(contentType: string): boolean | undefined {
    if (contentType)
      return (
        contentType.startsWith("image/") || contentType === CONTENT_TYPES.pdf
      );
  }

  return error ? (
    <div className="w-100 h-100 d-flex justify-content-center align-items-center">
      <ErrorMessage message={t("sis", { ns: "error" })}></ErrorMessage>
    </div>
  ) : loading || !docUrl ? (
    <div className="w-100 h-100 d-flex justify-content-center align-items-center">
      <Spinner animation="border" role="status" />
    </div>
  ) : (
    <div className="doc-frame">
      {isDisplayable(docUrl.contentType) ? (
        docUrl.contentType === CONTENT_TYPES.pdf ? (
          <iframe src={docUrl.url} />
        ) : (
          <div className="doc-container-img">
            <img src={docUrl.url} />
          </div>
        )
      ) : (
        <div className="w-100 d-flex flex-row justify-content-start align-items-center p-4">
          <div className="d-flex flex-column justify-content-center align-items-center">
            {DOC_ICONS[docUrl.contentType as keyof typeof DOC_ICONS] && (
              <img
                height={"100px"}
                width={"auto"}
                src={DOC_ICONS[docUrl.contentType as keyof typeof DOC_ICONS]}
              />
            )}
            <Button
              className="custom-primary-button w-100 btn btn-primary my-2"
              onClick={downloadDocument}
            >
              {t("download")}
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default DocFrame;
