import axios, { AxiosError, AxiosInstance, AxiosResponse } from "axios";
import { API_ERROR } from "../../constants/components-const";

import ServerError from "../../types/ServerError";
import { throwable } from "../../utilities/ts-throwable";

type ApiError = {
  code: number;
  message: string;
};

export type ApiResponseWrapper<T = any> = {
  code: number;
  data?: T;
  error?: ApiError;
};

export type InterceptorFunction<V = any> = {
  onFulfilled?: (value: V) => V | Promise<V>;
  onRejected?: (error: any) => any;
};

export type ConstructorParams = {
  responseInterceptor?: InterceptorFunction;
  requestInterceptor?: InterceptorFunction;
};

abstract class HttpClient {
  protected readonly instance: AxiosInstance;

  constructor(baseURL: string) {
    this.instance = axios.create({
      baseURL,
    });
  }

  public initializeInterceptors(params?: ConstructorParams) {
    this.initializeResponseInterceptor(params?.responseInterceptor);
    this.initializeRequestInterceptor(params?.requestInterceptor);
  }

  private initializeResponseInterceptor = (interceptor?: any) => {
    this.instance.interceptors.response.use(
      interceptor?.onFulfilled || this.handleResponse,
      interceptor?.onRejected || this.handleUncaughtError
    );
  };

  private initializeRequestInterceptor = (
    interceptor?: InterceptorFunction
  ) => {
    if (interceptor) {
      this.instance.interceptors.request.use(
        interceptor.onFulfilled,
        interceptor.onRejected
      );
    }
  };

  private handleResponse = <T = any>({
    data: wrapper,
  }: AxiosResponse<ApiResponseWrapper>): Promise<T> &
    throwable<ServerError> => {
    const { data, error } = wrapper;

    if (data) {
      return data;
    } else if (error) {
      throw new ServerError(error?.message, Number(error.code));
    }
    throw new ServerError(API_ERROR, 500);
  };

  protected handleUncaughtError = ({
    response,
  }: AxiosError<ApiResponseWrapper>): throwable<ServerError> => {
    if (response && response.data) {
      const { data } = response;
      const { code, error } = data;
      if (code && error) {
        throw new ServerError(error?.message, Number(error.code));
      }
    }
    throw new ServerError(API_ERROR, 500);
  };

  get = <P = any, R = any>(url: string, params?: P): Promise<R> =>
    this.instance.get(url, {
      params,
    });

  post = <P = any, R = any>(url: string, payload?: P): Promise<R> =>
    this.instance.post(url, payload);

  patch = <P = any, R = any>(url: string, payload: P): Promise<R> =>
    this.instance.patch(url, payload);

  delete = <P = any, R = any>(url: string, payload: P): Promise<R> =>
    // eslint-disable-next-line
    this.instance.delete(url, payload!);

  put = <P = any, R = any>(url: string, payload: P): Promise<R> =>
    this.instance.put(url, payload);
}

export default HttpClient;
