import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { ScrollToTop } from "./components/organims";
import Header from "./components/organims/Header";
import AppRoutes from "./navigation/routes";

function App() {
  return (
    <Router>
      <ScrollToTop />
      <Header>
        <AppRoutes />
      </Header>
    </Router>
  );
}

export default App;
