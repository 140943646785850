import {
  EXCEL,
  STATISTICS_ICON_1,
  STATISTICS_ICON_2,
  STATISTICS_ICON_3,
  STATISTICS_ICON_4,
  WORD,
} from "./../assets/index";
import {
  ABOUT_ICON_1,
  ABOUT_ICON_2,
  ABOUT_ICON_3,
  ABOUT_ICON_4,
  CIRCLE_COLOR,
  DOC_1,
  DOC_2,
  DOC_3,
  DOC_4,
  DOC_5,
  DOC_6,
  FRAGMENT,
  KEY_CONCEPTS,
  LEAF_SINIA,
  SIS_1,
  SIS_2,
  SIS_3,
  SIS_4,
  SIS_5,
  SIS_6,
} from "../assets";

export const SISCategories = {
  "e478a5e7-73bf-4a1d-850f-3a3a6463519a": {
    icon: SIS_1.default,
    name: "A. Compatibilidad de programas nacionales con convenciones y acuerdos",
  },
  "a2862117-2bad-455c-985d-30fe46fb1cb9": {
    icon: SIS_2.default,
    name: "E. Conservación de bosques y biodiversidad",
  },
  "cdb723eb-b73d-43a8-857c-a1c5816f5bac": {
    icon: SIS_4.default,
    name: "D. Participación plena y efectiva de pueblos indígenas y comunidades locales",
  },
  "42e16193-5db4-46a6-8a74-cbf1fca63d06": {
    icon: SIS_3.default,
    name: "C. Respeto a pueblos indígenas y comunidades locales",
  },
  "b5a14ff1-cdc6-4fcc-a8c5-783a29da490e": {
    icon: SIS_5.default,
    name: "F. Riesgos de reversión",
  },
  "88b3cc10-6382-4c7d-8013-023d93ba92e5": {
    icon: SIS_6.default,
    name: "B. Transparencia y eficacia en la gobernanza forestal nacional",
  },
};

export const DATA = {
  FrequentQuestionsSection: {
    questions: [
      "question0",
      "question1",
      "question2",
      "question3",
      "question4",
      "question5",
    ],
  },
  aboutSection: {
    logo: [
      {
        logo: KEY_CONCEPTS.default,
        translate: "logo1",
      },
      {
        logo: FRAGMENT.default,
        translate: "logo2",
      },
      {
        logo: LEAF_SINIA.default,
        translate: "logo3",
      },
      {
        logo: CIRCLE_COLOR.default,
        translate: "logo4",
      },
    ],
    bannerCard: [
      {
        logo: ABOUT_ICON_1.default,
        translate: "card1",
      },
      {
        logo: ABOUT_ICON_2.default,
        translate: "card2",
      },
      {
        logo: ABOUT_ICON_3.default,
        translate: "card3",
      },
      {
        logo: ABOUT_ICON_4.default,
        translate: "card4",
      },
    ],
    buttonURL: "http://ceniga.go.cr/",
  },
  statisticsSection: {
    instructionCards: [
      {
        icon: STATISTICS_ICON_1.default,
        translate: "instruction1",
      },
      {
        icon: STATISTICS_ICON_2.default,
        translate: "instruction2",
      },
      {
        icon: STATISTICS_ICON_3.default,
        translate: "instruction3",
      },
      {
        icon: STATISTICS_ICON_4.default,
        translate: "instruction4",
      },
    ],
  },
  docsSection: {
    docs: [
      {
        id: "1",
        image: DOC_1,
        translate: "doc1",
        docs: [
          {
            image: DOC_1,
            txt: "Capitulo I. Informe del Estado Ambiente",
            link: "http://sinia.go.cr/wp-content/uploads/2019/06/Capitulo-I-2018.pdf",
          },
          {
            image: DOC_1,
            txt: "Capítulo II. Informe Estado Ambiente",
            link: "http://sinia.go.cr/wp-content/uploads/2019/05/Capitulo-II-2018-estadoambiente.pdf",
          },
          {
            image: DOC_1,
            txt: "Capitulo III. Informe del Estado Ambiente",
            link: "http://sinia.go.cr/wp-content/uploads/2019/05/Capitulo-III-2018.pdf",
          },
          {
            image: DOC_1,
            txt: "Capítulo IV. Informe del Estado Ambiente",
            link: "http://sinia.go.cr/wp-content/uploads/2020/06/04-Capitulo-IV-2018-PDF-web.pdf",
          },
          {
            image: DOC_1,
            txt: "Anexo Estadístico. Informe del Estado Ambiente",
            link: "http://sinia.go.cr/wp-content/uploads/2020/06/05-Anexo-Estadistico-PDF-web.pdf",
          },
        ],
      },
      {
        id: "2",
        image: DOC_2,
        translate: "doc2",
        docs: [
          {
            txt: "Informe Bienal de Actualización ante la Convención Marco de las Naciones Unidas sobre el Cambio Climático",
            link: "http://sinia.go.cr/wp-content/uploads/2019/06/BUR-2015_compressed.pdf",
          },
          {
            txt: "Primera Comunicación Nacional ante la Convención Marco de las Naciones Unidas sobre el Cambio Climático",
            link: "http://sinia.go.cr/wp-content/uploads/2019/06/Primera-Convencio%CC%81n-de-Lucha-contra-la-Desertificacio%CC%81n_compressed.pdf",
          },
          {
            txt: "Segunda Comunicación Nacional ante la Convención Marco de las Naciones Unidas sobre el Cambio Climático",
            link: "http://sinia.go.cr/wp-content/uploads/2019/06/segundaComunicacionNacional_compressed-1.pdf",
          },
          {
            txt: "Tercera Comunicación Nacional ante la Convención Marco de las Naciones Unidas sobre el Cambio Climático",
            link: "http://sinia.go.cr/wp-content/uploads/2019/06/Tercera-convencio%CC%81n-de-Lucha-contra-la-desertificacio%CC%81n_compressed.pdf",
          },
        ],
      },
      {
        id: "3",
        image: DOC_3,
        translate: "doc3",
        docs: [
          {
            txt: "Informes país a la Convención de las Naciones Unidas de Lucha contra la Desertificación",
            link: "http://sinia.go.cr/wp-content/uploads/2019/05/unccd_Costa-Rica_2018.pdf",
          },
        ],
      },
      {
        id: "4",
        image: DOC_4,
        translate: "doc4",
        docs: [
          {
            txt: "Informes país a la Convención sobre la Diversidad Biológica",
            link: "http://sinia.go.cr/wp-content/uploads/2019/06/cr-nr-06-p1-es_compressed.pdf",
          },
        ],
      },
      {
        id: "5",
        image: DOC_5,
        translate: "doc5",
        docs: [
          {
            txt: "Examen del proceso de adhesión de Costa Rica a la OCDE en las áreas de medio ambiente y residuos",
            link: "http://sinia.go.cr/wp-content/uploads/2020/04/VF-ES-Informe-Evaluaci%C3%B3n-Ambiental.pdf",
          },
          {
            txt: "OECD Accession Review of Costa Rica in the Fields of Environment and Waste",
            link: "http://sinia.go.cr/wp-content/uploads/2020/04/ENV-EPOC201918-FINAL.en_.pdf",
          },
        ],
      },
      {
        id: "6",
        image: DOC_6,
        translate: "doc6",
        docs: [
          {
            txt: "Guía metodológica para la publicación en el sistema de indicadores y mapas del Informe del Estado del Ambiente",
            link: "http://sinia.go.cr/wp-content/uploads/2022/02/CENIGA-058-Guia-Metodologica_Estadistica-_Mapas.pdf",
          },
          {
            txt: "GUÍA PARA VALIDACIÓN TOPOLÓGICA DE LA GEOMETRÍA DE LOS ELEMENTOS VECTORIALES",
            link: "http://sinia.go.cr/wp-content/uploads/2022/01/CENIGA-059-Guia-Validacion-Topolotica-SINIA.pdf",
          },
          {
            txt: "GUÍA PARA CREACIÓN DE LA TABLA DE ATRIBUTOS DE LAS CAPAS VECTORIALES ",
            link: "http://sinia.go.cr/wp-content/uploads/2022/01/CENIGA-060-Guia-Tabla-de-Atributos.pdf",
          },
        ],
      },
    ],
  },
  directory: [
    {
      translate: "item1",
      subsections: [
        {
          translate: "subItem1",
          website: "http://www.cenat.ac.cr/es/",
          phone: "2519-5700",
        },
        {
          translate: "subItem2",
          website: "https://ucr.ac.cr/",
        },
        {
          translate: "subItem3",
          website: "https://una.ac.cr/",
        },
        {
          translate: "subItem4",
          website: "https://www.tec.ac.cr/",
        },
        {
          translate: "subItem5",
          website: "https://utn.ac.cr/",
        },
      ],
    },
    {
      translate: "item2",
      subsections: [
        {
          translate: "subItem1",
          website: "http://www.da.go.cr/",
          phone: "2221-7514 / 2221-7510",
        },
        {
          translate: "subItem2",
          website: "http://www.senara.or.cr/",
          phone: "2257-9733",
        },
        {
          translate: "subItem3",
          website: "https://www.aya.go.cr/SitePages/Principal.aspx",
        },
      ],
    },
    {
      translate: "item3",
      subsections: [
        {
          translate: "subItem1",
          website: "http://www.digeca.go.cr/",
          phone: "2258-3272 / 2233-0356 / 2257-1839",
        },
        {
          translate: "subItem2",
          website: "https://www.fonafifo.go.cr/",
          phone: "2545-3500",
          email: "fonafifo@fonafifo.com",
        },
        {
          translate: "subItem3",
          website: "https://www.imn.ac.cr/web/imn/inicio",
          phone: "2222-5616",
          email: "imn@imn.ac.cr",
        },
        {
          translate: "subItem4",
          website: "http://www.sinac.go.cr/",
          phone: "2522-6500",
          email: "info@sinac.go.cr",
        },
        {
          translate: "subItem5",
          website: "https://www.setena.go.cr/",
          phone: "2234-3420",
        },
        {
          translate: "subItem6",
          phone: "2257-7130",
        },
        {
          translate: "subItem7",
          website: "https://www.conagebio.go.cr",
          phone: "2253-8416",
        },
        {
          translate: "subItem8",
          phone: "2547-6930",
        },
        {
          translate: "subItem9",
          phone: "2257-1839",
        },
        {
          translate: "subItem10",
          website: "http://www.fao.org/redd/es/",
        },
      ],
    },
    {
      translate: "item4",
      subsections: [
        {
          translate: "subItem1",
          website: "https://web.energia.go.cr/",
          phone: "(506) 2233-4533",
          email: "direccionenergia@minae.go.cr",
        },
        {
          translate: "subItem2",
          website: "http://www.geologia.go.cr/",
          phone: "2234-0204 / 2234-8246",
          email: "geologia@minae.go.cr",
        },
        {
          translate: "subItem3",
          website: "https://www.grupoice.com/wps/portal",
          phone: "2000-7720",
        },
      ],
    },
    {
      translate: "item5",
      subsections: [
        {
          translate: "subItem1",
          website: "http://www.mag.go.cr/",
          phone: "2105-6100",
        },
        {
          translate: "subItem2",
          website: "http://www.sepsa.go.cr/",
          phone: "2231-1051",
          email: "direccionsepsa@mag.go.cr",
        },
        {
          translate: "subItem3",
          website: "https://www.catie.ac.cr/",
          phone: "2558-2000",
          email: "comunica@catie.ac.cr/catie@catie.ac.cr",
        },
      ],
    },
    {
      translate: "item6",
      subsections: [
        {
          translate: "subItem1",
          website: "http://www.snitcr.go.cr/instituto_geografico_nacional",
          phone: "2202-0601",
        },
        {
          translate: "subItem2",
          website: "www.inec.go.cr",
          phone: "2280-9280",
          email: "informacion@inec.go.cr",
        },
        {
          translate: "subItem3",
          website: "https://www.ministeriodesalud.go.cr/",
        },
        {
          translate: "subItem4",
          website: "https://www.bccr.fi.cr/SitePages/default.aspx",
          phone: "2243-3333",
        },
        {
          translate: "subItem5",
          website: "https://www.esph-sa.com/",
          phone: "2237-6566",
          email: "info@esph-sa.com",
        },
        {
          translate: "subItem6",
          phone: "2258-0069",
        },
      ],
    },
  ],
  environmental: [
    {
      translate: "item1",
      subsections: [
        {
          translate: "subItem1",
          links: [
            "http://www.sitada.go.cr/denunciaspublico/",
            "http://www.sitada.go.cr/denunciaspublico/graf_tipodelito.aspx",
            "http://www.sitada.go.cr/denunciaspublico/cons_delitos.aspx",
            "http://www.sitada.go.cr/denunciaspublico/map.aspx",
            "https://www.contraloriaambiental.go.cr/pag/formulario_crear_solicitud.php",
            "https://siprecca.contraloriaambiental.go.cr/",
            "https://www.contraloriaambiental.go.cr/pag/principal.php",
          ],
        },
        {
          translate: "subItem2",
          links: [
            "https://cambioclimatico.go.cr/recursos/sinamecc/",
            "http://sinamecc.go.cr/",
            "http://sinamecc.opendata.junar.com/home",
          ],
        },
        {
          translate: "subItem3",
          links: [
            "https://www.imn.ac.cr/reporte-pronostico-regional",
            "https://www.imn.ac.cr/mapa",
            "https://www.imn.ac.cr/estaciones-automaticas",
          ],
        },
        {
          translate: "subItem4",
          links: [
            "https://onfcr.org/publicaciones-tecnicas-onf/usos-y-aportes-de-la-madera-en-costa-rica",
            "https://onfcr.org/publicaciones-tecnicas-onf/precios-de-la-madera",
          ],
        },
      ],
    },
    {
      translate: "item2",
      subsections: [
        {
          translate: "subItem1",
          links: [
            "http://www.sinac.go.cr/ES/buscador/Paginas/default.aspx",
            "http://www.sinac.go.cr/ES/asp/Paginas/default.aspx",
            "http://www.sirefor.go.cr/",
          ],
        },
        {
          translate: "subItem2",
          links: [
            "https://www.conagebio.go.cr/Conagebio/login.xhtml",
            "https://www.conagebio.go.cr/Conagebio/public/permisosInfoPueblos.html",
            "http://enbcr.org/",
          ],
        },
        {
          translate: "subItem3",
          links: [
            "https://www.fonafifo.go.cr/es/servicios/estadisticas-de-psa/",
            "https://www.fonafifo.go.cr/es/transparencia-institucional/",
            "https://www.fonafifo.go.cr/es/servicios/consultas/",
            "https://www.fonafifo.go.cr/es/servicios/programa-de-plantaciones-de-aprovechamiento-forestal/",
          ],
        },
      ],
    },
    {
      translate: "item3",
      subsections: [
        {
          translate: "subItem1",
          links: [
            "https://sepse.go.cr/ciena/balances-energeticos/",
            "https://sepse.go.cr/ciena/encuestas-sectoriales/",
          ],
        },
        {
          translate: "subItem2",
          links: ["http://direcciondecombustiblesminaecr.blogspot.com/"],
        },
        {
          translate: "subItem3",
          links: [
            "https://aresep.go.cr/combustible",
            "https://apps.grupoice.com/CenceWeb/CenceCurvaDemandaSEN.jsf?init=true",
            "https://web.energia.go.cr/#",
          ],
        },
      ],
    },
    {
      translate: "item4",
      subsections: [
        {
          translate: "subItem1",
          links: [
            "http://www.da.go.cr/informacion-geoespacial/",
            "http://mapas.da.go.cr/mapnew.php",
            "http://mapas.da.go.cr/geonetwork/srv/spa/catalog.search#/home",
            "http://visor.da.go.cr/start.php",
            "http://mapas.da.go.cr/geoservicios.php",
            "http://visor.da.go.cr/geoservicios.php",
            "https://apps.da.go.cr/senara/",
            "https://apps.da.go.cr/consulta_expedientes/",
          ],
        },
        {
          translate: "subItem2",
          links: ["https://www.parquemarino.org/"],
        },
      ],
    },
    {
      translate: "item5",
      subsections: [
        {
          translate: "subItem1",
          links: [
            "http://www.geologia.go.cr/mineria/expediente_minero.aspx",
            "http://www.geologia.go.cr/geologia/LugaresInteresGeologico.aspx",
            "http://www.geologia.go.cr/mineria/tramites_en_linea.aspx",
            "http://www.geologia.go.cr/quienes_somos/transparencia.aspx",
          ],
        },
        {
          translate: "subItem2",
          links: [
            "https://edi.setena.go.cr/",
            "http://indicadores.setena.go.cr/",
            "https://www.setena.go.cr/listado-expedientes/",
            "https://www.setena.go.cr/expedientes-planes-ordenamiento-territorial-ingresados/",
          ],
        },
        {
          translate: "subItem3",
          links: [
            "http://www.digeca.go.cr/",
            "http://www.digeca.go.cr/proyectos-vigentes",
          ],
        },
      ],
    },
  ],
};

export const CONTENT_TYPES = {
  png: "image/png",
  pdf: "application/pdf",
  excel: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  word: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
};

export const DOC_ICONS = {
  [CONTENT_TYPES.word]: WORD.default,
  [CONTENT_TYPES.excel]: EXCEL.default,
};

export const CENIGA_URL = "https://ceniga.go.cr/sis/";
export const CMNUCC_URL =
  "https://redd.unfccc.int/submissions.html?country=cri";
