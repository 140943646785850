import {
  CATEGORIES,
  SUB_CATEGORIES,
  INDICATORS,
  VARIABLES,
} from "../../constants/endpoints";
import {
  Category,
  IIndicatorsService,
  Indicator,
  SubCategorie,
  Variable,
} from "../../types/api/indicators";
import ServerError from "../../types/ServerError";
import { throwable } from "../../utilities/ts-throwable";
import ApiClient from "./apiClient";

class IndicatorsService implements IIndicatorsService {
  protected readonly apiClient: ApiClient;

  public constructor() {
    this.apiClient = new ApiClient(process.env.REACT_APP_BASE_URL || "");
  }

  getCategories = (): Promise<Category[]> & throwable<ServerError> => {
    return this.apiClient.get(CATEGORIES);
  };

  getSubCategories = (
    categorieID: string
  ): Promise<SubCategorie[]> & throwable<ServerError> => {
    return this.apiClient.get(SUB_CATEGORIES.replace(":id", categorieID));
  };

  getIndicators = (
    variableId: string
  ): Promise<Indicator[]> & throwable<ServerError> => {
    return this.apiClient.get(INDICATORS.replace(":id", variableId));
  };

  getVariables = (
    SubcategoryID: string
  ): Promise<Variable[]> & throwable<ServerError> => {
    return this.apiClient.get(VARIABLES.replace(":id", SubcategoryID));
  };
}

export default IndicatorsService;
