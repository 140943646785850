import { FC, ReactNode, useEffect, useState } from "react";
import { SINIA_ICON } from "../../../../assets";
import "./styles.scss";

export interface TopicDetailHeaderProps {
  name: string;
  iconSrc?: string;
  description?: string;
  button?: ReactNode;
}

const TopicDetailHeader: FC<TopicDetailHeaderProps> = ({
  iconSrc,
  name,
  description,
  button,
}) => {
  const [showDescription, setShowDescription] = useState(true);

  const resize = () => {
    setShowDescription(window.innerWidth > 760);
  };

  useEffect(() => {
    window.addEventListener("resize", resize.bind(this));
    resize();
  }, []);

  return (
    <div className="w-100 p-4 d-flex align-items-stretch topic-header-container">
      <div className="img-container">
        <img alt="Imagen topic" src={iconSrc ? iconSrc : SINIA_ICON} />
      </div>
      <div className="w-100 d-flex flex-row justify-content-between align-items-center">
        <div className="card-txt-container h-100 d-flex flex-column  align-items-start justify-content-center">
          <h3>{name}</h3>
          {description && showDescription && <p>{description}</p>}
        </div>
        {button}
      </div>
    </div>
  );
};

export default TopicDetailHeader;
