export const transformNames = (name: string) => {
  return name
    .split("")
    .map((char, index, arr) => {
      if (/[áéíóúÁÉÍÓÚ]/.test(char) && index < arr.length - 1) {
        arr[index + 1] = arr[index + 1].toLowerCase();
      }
      return char;
    })
    .join("");
};
