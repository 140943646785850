import { useDispatch, useSelector } from "react-redux";
import SISService from "../services/api/sisService";
import { useEffect } from "react";
import {
  getLibrarySuccess,
  getSISFailure,
  getSISStart,
  getSISSuccess,
} from "../reducers/sisReducer/actions";
import { SIS } from "../types/api/sis";
import { SISCategories } from "../constants/commond";
import { SINIA_ICON } from "../assets";

const useSIS = () => {
  const { loading, sis, library } = useSelector((state: any) => state.sis);
  const dispatch = useDispatch();
  const service = new SISService();

  useEffect(() => {
    if (!sis || sis.length === 0 || !library) {
      dispatch(getSISStart());
      service
        .getCategories()
        .then((response: SIS[]) => {
          const catWithIcons = response
            .filter(
              (cat) => SISCategories[cat.id as keyof typeof SISCategories]
            )
            .map((cat) => {
              const newCat = {
                icon: SISCategories[cat.id as keyof typeof SISCategories].icon,
                name: SISCategories[cat.id as keyof typeof SISCategories].name,
                description: cat.description,
                id: cat.id,
              };

              return newCat;
            })
            .sort((a, b) => a.name.localeCompare(b.name));
          const library = response.find(
            (cat) => !SISCategories[cat.id as keyof typeof SISCategories]
          );
          if (library) {
            const lib = {
              icon: SINIA_ICON,
              name: library.name,
              description: library.description,
              id: library.id,
            };
            dispatch(getLibrarySuccess(lib));
          }
          dispatch(getSISSuccess(catWithIcons));
        })
        .catch((err) => dispatch(getSISFailure(err)));
    }
  }, []);

  return {
    sis,
    loading,
    library,
  };
};

export default useSIS;
