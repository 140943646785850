import { FC, useState } from "react";
import "./styles.scss";
import { SISItem } from "../../../../types/api/sis";

interface SISItemProps {
  item: SISItem;
  setIndicator: (selected: any) => void;
  setDocument: (selected: any) => void;
  indicatorId: string;
}

const SISListItem: FC<SISItemProps> = ({
  item,
  setIndicator,
  setDocument,
  indicatorId,
}) => {
  const [open, setOpen] = useState(false);

  return (
    <div
      className={`sis-list-item sis-list-item-${
        open ? "open" : "closed"
      } w-100 px-2`}
      role="button"
    >
      <div
        className="d-inline-flex justify-content-between align-items-center w-100 p-2 gap-2"
        onClick={() => {
          setOpen(!open);
          if (indicatorId !== item.id) setDocument(null);
          setIndicator(item);
        }}
      >
        <i className="bi bi-folder"></i>
        <p className="text-start w-100 m-0 fw-bold">{item.name}</p>
        {item.documents.length > 0 && (
          <i className={`bi bi-caret-${open ? "down" : "up"}-fill`}></i>
        )}
      </div>

      {open &&
        item.documents.map((doc, key) => (
          <div
            className="ps-4 sis-list-doc d-flex flex-row gap-2 break-file-name"
            key={key}
            onClick={() => {
              if (indicatorId !== item.id) setIndicator(item);
              setDocument(doc);
            }}
          >
            <i className="bi bi-file-earmark"></i>
            <div>{doc.name}</div>
          </div>
        ))}
      <hr className="m-0" />
    </div>
  );
};

interface SISBarProps {
  items: SISItem[];
  setIndicator: (selected: any) => void;
  setDocument: (selected: any) => void;
  indicatorId: string;
}

const SISBar: FC<SISBarProps> = ({
  items,
  setIndicator,
  setDocument,
  indicatorId,
}) => {
  return (
    <div className="sis-list py-2">
      {items.map((item, key) => (
        <SISListItem
          key={key}
          item={item}
          setIndicator={setIndicator}
          setDocument={setDocument}
          indicatorId={indicatorId}
        />
      ))}
    </div>
  );
};

export default SISBar;
