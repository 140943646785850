import { combineReducers } from "redux";
import indicatorReducer from "./indicatorReducer/indicatorReducer";
import SISReducer from "./sisReducer/reducer";

const rootReducer = combineReducers({
  indicators: indicatorReducer,
  sis: SISReducer,
});

export default rootReducer;
