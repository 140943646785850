import { useEffect, useState } from "react";
import SISService from "../../../services/api/sisService";

export const useDocFrame = (
  selectedIndicator: any,
  selectedDocument: any,
  setDocUrl: any,
  docUrl: any
) => {
  const service = new SISService();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const downloadDocument = () => {
    const link = document.createElement("a");
    link.href = docUrl.url;
    link.setAttribute("download", selectedDocument!.name);
    document.body.appendChild(link);
    link.click();
    link.remove();
    window.URL.revokeObjectURL(docUrl.url);
  };

  useEffect(() => {
    setError(false);
    if (selectedDocument) {
      const controller = new AbortController();

      setLoading(true);
      service
        .getFileURL(selectedDocument.id, controller.signal)
        .then((url) => {
          setDocUrl(url);
        })
        .catch((err) => {
          if (!err.aborted) {
            console.log(err);
            setError(true);
          }
        })
        .finally(() => setLoading(false));

      return () => controller.abort();
    }
  }, [selectedDocument, selectedIndicator]);

  return { loading, downloadDocument, error };
};
