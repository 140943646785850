import { useTranslation } from "react-i18next";
import { CustomBreadcrumb, ErrorMessage, Loader } from "../../moleculas";
import { useSISDetail } from "./useSISDetail";
import { HOME, SIS } from "../../../constants/routes";
import { DocumentsContainer } from "../../organims";

const SISDetail = () => {
  const { t } = useTranslation("sis");
  const { loading, data, items } = useSISDetail();

  return !data ? (
    loading ? (
      <Loader />
    ) : (
      <div className="w-100 h-100 d-flex justify-content-center align-items-center">
        <ErrorMessage message={t("sis", { ns: "error" })}></ErrorMessage>
      </div>
    )
  ) : (
    <div className="w-100 d-flex justify-content-md-start justify-content-lg-start flex-column">
      <div className="w-100 d-flex flex-column align-items-center">
        <div className="w-75 align-items-left breadcrumb-height">
          <CustomBreadcrumb
            isWhite={false}
            breadcrumbs={[
              {
                name: t("home"),
                href: HOME,
              },
              { name: t("title"), href: SIS },
              { name: data.name },
            ]}
          />
        </div>
        <div className="w-75 mb-5 align-items-left">
          <DocumentsContainer
            name={data.name}
            description={data.description}
            icon={data.icon}
            items={items}
            loading={loading}
          />
        </div>
      </div>
    </div>
  );
};

export default SISDetail;
