import {
  GET_LIBRARY_SUCCESS,
  GET_SIS_FAILURE,
  GET_SIS_STARTED,
  GET_SIS_SUCCESS,
} from "../../constants/reduceActionTypes";

const getSISStart = () => ({
  type: GET_SIS_STARTED,
});

const getSISSuccess = (payload: any) => ({
  type: GET_SIS_SUCCESS,
  payload: payload,
});

const getSISFailure = (error: any) => ({
  type: GET_SIS_FAILURE,
  payload: error,
});

const getLibrarySuccess = (payload: any) => ({
  type: GET_LIBRARY_SUCCESS,
  payload: payload,
});

export { getSISStart, getSISSuccess, getSISFailure, getLibrarySuccess };
