import { Spinner } from "react-bootstrap";
import "./styles.scss";
import DocFrame from "../DocFrame";
import SISBar from "./Sidebar";
import useDocContainer from "./useDocContainer";
import { FC } from "react";
import { SISItem } from "../../../types/api/sis";
import { SINIA_ICON } from "../../../assets";

interface DocContainerProps {
  name: string;
  icon: string;
  description: string[];
  items: SISItem[];
  loading: boolean;
}

const DocumentsContainer: FC<DocContainerProps> = ({
  items,
  loading,
  name,
  icon,
  description,
}) => {
  const {
    setSelectedDocument,
    setSelectedIndicator,
    selectedIndicator,
    selectedDocument,
    setDocUrl,
    docUrl,
    info,
  } = useDocContainer();

  return (
    <div className="shadow rounded">
      <div className="w-100 mt-y mb-0 rounded-top border-top border-start border-end p-4 d-flex flex-row align-items-start gap-4">
        <div className="sis-img-container">
          <img alt="Imagen topic" src={icon ? icon : SINIA_ICON} />
        </div>
        <div className="h-100 d-flex flex-column  align-items-start justify-content-center">
          <h3>{name}</h3>
          <div>{description}</div>
        </div>
      </div>
      <div className="sis-container border rounded-bottom">
        {loading ? (
          <div className="w-100 h-100 d-flex justify-content-center align-items-center">
            <Spinner animation="border" role="status" />
          </div>
        ) : (
          <div className="w-100 d-flex flex-column flex-md-row align-items-stretch sis-container-body">
            <div className="col-container h-100 overflow-auto pe-2">
              <SISBar
                items={items}
                setDocument={setSelectedDocument}
                setIndicator={setSelectedIndicator}
                indicatorId={selectedIndicator.id}
              />
            </div>

            <div className="doc d-flex flex-column p-2">
              <div className="row doc-info">
                <h2 className="break-file-name">{info.name}</h2>
                <div>
                  {info &&
                    info.description &&
                    info.description.map((desc: string, index: string) => (
                      <div key={index}>{desc}</div>
                    ))}
                </div>
              </div>
              <div className="doc-container">
                {selectedDocument && (
                  <DocFrame
                    docUrl={docUrl}
                    selectedIndicator={selectedIndicator}
                    selectedDocument={selectedDocument}
                    setDocUrl={setDocUrl}
                  />
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default DocumentsContainer;
