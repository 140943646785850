import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { SIS, SISItem } from "../../../types/api/sis";
import SISService from "../../../services/api/sisService";
import useSIS from "../../../hooks/useSIS";
import { transformNames } from "../../../utilities/transformNames";

export function useSISDetail() {
  const location = useLocation();
  const id = location.pathname.split("/").slice(-1)[0];
  const service = new SISService();
  const [items, setItems] = useState<SISItem[]>([]);
  const [loadingIndicators, setLoadingIndicators] = useState(true);
  const { loading, sis, library } = useSIS();
  const [data, setData] = useState<{
    id: string;
    name: string;
    description: string[];
    icon: string;
  } | null>(null);

  useEffect(() => {
    if (!loading && sis.length > 0 && !data) {
      const cat = sis.find((c: SIS) => c.id === id);
      if (cat) {
        setData({
          ...cat,
        });
      } else if (!cat && library && id === library.id) {
        setData(library);
      }
    }
  }, [sis]);

  const getIndicators = async (id: string) => {
    setLoadingIndicators(true);
    service
      .getIndicators(id)
      .then((res) => {
        const transformedTitles = res.map((indicator: any) => ({
          ...indicator,
          name: transformNames(indicator.name),
          documents: indicator.documents.map((doc: any) => ({
            ...doc,
            name: transformNames(doc.name),
            description: doc.description.split(/\n/),
          })),
          description: indicator.description.split(/\n/),
        }));
        setItems(transformedTitles);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => setLoadingIndicators(false));
  };

  useEffect(() => {
    if (id) {
      getIndicators(id);
    }
  }, []);

  return {
    loading: loadingIndicators,
    data,
    items,
  };
}
