import { useEffect, useState } from "react";
import { SISDocument, SISItem } from "../../../types/api/sis";

const useDocContainer = () => {
  const [selectedIndicator, setSelectedIndicator] = useState<SISItem>(
    {} as SISItem
  );
  const [selectedDocument, setSelectedDocument] = useState<SISDocument | null>(
    null
  );
  const [docUrl, setDocUrl] = useState(null);
  const [info, setInfo] = useState({} as any);

  useEffect(() => {
    if (selectedIndicator) {
      setDocUrl(null);
      setInfo({
        name: selectedIndicator.name,
        description: selectedIndicator.description,
        info: selectedIndicator.id,
      });
    }
  }, [selectedIndicator]);

  useEffect(() => {
    if (selectedDocument) {
      setInfo({
        name: selectedDocument.name,
        description: selectedDocument.description,
      });
      setDocUrl(null);
    }
  }, [selectedDocument]);

  return {
    setSelectedDocument,
    setSelectedIndicator,
    selectedIndicator,
    selectedDocument,
    setDocUrl,
    info,
    docUrl,
  };
};

export default useDocContainer;
